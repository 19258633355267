<template>
  <main class="page page-ministry">
    <BreadcrumbsComponent :title="currentTitle" :links="$route.meta.links" />
    <div class="page__container">
      <div class="page__content">
        <AsideNavigatorComponent :routes="asideLinksWithActivities" />
        <RouterView :key="$route.fullPath" />
      </div>
    </div>
    <div class="page__more" v-if="workers.length">
      <div class="page__more-container">
        <span class="title">Смотрите также</span>
        <div class="page__more-content page__more-content--col-3">
          <PersonComponent small v-for="(worker, i) in workers" :data="worker" :key="i" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import AsideNavigatorComponent from "components/navigation/AsideNavigatorComponent.vue";
import PersonComponent from "components/PersonComponent.vue";
import DEPARTMENT from "gql/queries/department.graphql";

export default {
  name: "MinistryPage",
  inject: ["types_limitation"],
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEPARTMENT,
      })
      .then(({ data }) => {
        store.dispatch("save", data);
      })
      .catch(() => {});
  },
  data() {
    return {};
  },
  computed: {
    asideLinks() {
      return [
        // {
        //   title: this.departmentAbout,
        //   to: {
        //     name: "ministry-about",
        //   },
        // },
        {
          title: "Руководство",
          to: {
            name: "ministry-management",
          },
        },
        !!this.$store.state.sections.filter((s) => s.workers.length).length ? {
          title: "Структура",
          to: {
            name: "ministry-structure",
          },
        } : null,
        {
          title: "Подведомственные учреждения",
          disabled: this.types_limitation,
          to: {
            name: "ministry-departments",
          },
        },
        {
          title: "Цели и задачи",
          to: {
            name: "ministry-goals",
          },
        },
      ];
    },
    asideLinksWithActivities() {
      return this.asideLinks
        .filter((i) => (i !== null && (!i.disabled || !i.disabled.includes(this.system_type.code))))
        .concat(
          this.main_activities.map((a) => ({
            title: a.title,
            to: {
              name: "ministry-activity-item",
              params: {
                id: a.id,
              },
            },
          }))
        );
    },
    system_type() {
      return this.$store.state.department?.system_type || {};
    },
    types() {
      return this.$store.state._types;
    },
    main_activities() {
      return this.$store.state.activities.filter((a) => a.type.code === 2); // 2 - Страница «О Министерстве»
    },
    workers() {
      return this.$store.state.ministry.ministry_more_workers.data.filter((v) => !!v && !!v.name);
    },
    departmentAbout() {
      switch (this.system_type.code) {
        case this.types.SYSTEM_DEPARTMENT: {
          return "Об управлении";
        }
        case this.types.SYSTEM_COMITET: {
          return "О комитете";
        }
        case this.types.SYSTEM_INSPECTION: {
          return "Об инспекции";
        }
        case this.types.SYSTEM_AGENCY: {
          return "Об агентстве";
        }
        case this.types.SYSTEM_UPOLNOMOCH: {
          return "Об Уполномоченном";
        }
        case this.types.SYSTEM_SERVICE: {
          return "О службе";
        }
        case this.types.SYSTEM_SOVET: {
          return "О совете";
        }
        case this.types.SYSTEM_VEDOMSTVO: {
          return "О ведомстве";
        }
        case this.$store.state._types.SYSTEM_ORGANISATION: {
          return "Об организации";
        }
        case this.$store.state._types.SYSTEM_UCHRED: {
          return "Об учреждении";
        }
      }
      return "О министерстве";
    },
    currentTitle() {
      const staticAsideIndex = this.asideLinksWithActivities
        .map((l) => {
          return l.to.name;
        })
        .findIndex((l) => {
          return l === this.$route.name;
        });
      if (staticAsideIndex >= 0) {
        if (this.$route.params?.id) {
          return this.activities_item.title;
        }
        return this.asideLinksWithActivities[staticAsideIndex]?.title;
      }
      return this.$store.state.ministry.ministry_breadcrumbs_title;
    },
    activities_item() {
      return this.$store.state.activity?.activities_item || {};
    },
  },
  components: { PersonComponent, AsideNavigatorComponent, BreadcrumbsComponent },
};
</script>

<style lang="stylus">
.page-ministry {
  margin-bottom 0

  .page__content {
    grid-template-columns 328px 1fr
    grid-gap 30px
    align-items start
    margin-bottom 50px
    +below(1280px) {
      margin-bottom 30px
      grid-template-columns 1fr
    }
  }
}
</style>
