<template>
  <aside class="aside-navigator">
    <ul class="aside-navigator__links">
      <li v-for="(link, i) in routes" :key="i">
        <router-link :to="link.to">{{ link.title }}</router-link>
      </li>
    </ul>
    <button type="button" class="aside-navigator__button" @click="$store.state.smallMenu = routes">
      <span>Разделы</span>
      <IconComponent category="vuesax-bold" name="menu" />
    </button>
  </aside>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "AsideNavigatorComponent",
  components: { IconComponent },
  props: {
    routes: {
      type: Array,
    },
  },
};
</script>

<style lang="stylus">
.aside-navigator {
  position sticky
  top 20px

  border-radius 10px
  background var(--white)
  padding 10px

  +below(1280px) {
    position static
    background none
    padding 0
    border-radius none
  }

  &__button {
    //max-width 350px
    width 100%
    background var(--main)
    border-radius 5px
    display inline-flex
    align-items center
    gap 10px
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--white);
    border none
    height 50px
    padding 0 20px
    justify-content center

    +below(1280px) {
      max-width 100%
      width 100%
    }
    +above(1281px) {
      display none
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--white)
      }
    }
  }

  &__links {
    display grid
    width 100%
    +below(1280px) {
      display none
    }

    li {
      display flex
      width 100%

      a {
        width 100%
        padding 15px
        font-weight: 500;
        font-size: 1.125em;
        line-height: 28px;
        color: var(--dark);
        transition var(--transition)
        border-radius 5px

        &.router-link-active {
          background var(--main)
          color var(--white)
        }
      }
    }
  }
}
</style>
